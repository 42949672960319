import { ApiReadProvider } from 'api-read-hook';
import * as React from 'react';

import { ApiRequestOptions } from './frolo-api-request';
import useApiCallable from './use-api-callable';

type Props = {
  children: React.ReactNode;
};

/**
 * Hook providing a `reader` matching the `api-read-hook` spec
 */
function useReader() {
  const callApi = useApiCallable();

  return React.useCallback(
    async function reader<R, W>(
      url: string,
      options: Omit<ApiRequestOptions<W>, 'url' | 'token' | 'fcmToken'> = {}
    ): Promise<R> {
      const response = await callApi<R, W>(url, options, {
        errorHandler: (err) => {
          // - 401 refresh handled by callApi
          // - Network errors and unexpected to be thrown for ScreenError
          throw err;
        },
      });
      if (!response) {
        throw new Error('Logic error: useReader should always have a response');
      }
      return response.data;
    },
    [callApi]
  );
}

export default function FroloApiReadProvider({ children }: Props) {
  const reader = useReader();
  return <ApiReadProvider config={{ reader }}>{children}</ApiReadProvider>;
}
