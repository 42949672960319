import { ReadResult, useApiRead } from 'api-read-hook';
import * as React from 'react';
import { Api } from '@/api';
import { useAmplifyAuthContext } from '.';
import { ScreenError, ScreenLoading } from '@/components';

type Props = {
  children: React.ReactNode;
};

const UserDataContext = React.createContext<{
  user: Api.MyStatus.V['user'] | null;
  apiMyStatus: ReadResult<Api.MyStatus.V>;
}>({
  apiMyStatus: undefined as any,
  user: null,
});

export function UserDataContextProvider({ children }: Props) {
  const { amplifyUser } = useAmplifyAuthContext();

  const isLoggedIn = !!amplifyUser;

  const apiMyStatus = useApiRead<Api.MyStatus.V>(
    isLoggedIn ? '/my-status' : null,
    { staleWhileInvalidated: true }
  );

  const contextValue = React.useMemo(
    () =>
      apiMyStatus.data
        ? {
            apiMyStatus,
            user: apiMyStatus.data.user,
          }
        : {
            apiMyStatus,
            user: null,
          },
    [apiMyStatus]
  );

  if (apiMyStatus.error) {
    return (
      <ScreenError origin="UserDataContext.apiMyStatus" {...apiMyStatus} />
    );
  }
  if (!apiMyStatus.data && isLoggedIn) {
    return <ScreenLoading message="Fetching your new data" />;
  }

  return (
    <UserDataContext.Provider value={contextValue}>
      {children}
    </UserDataContext.Provider>
  );
}

export function useUserDataContext() {
  return React.useContext(UserDataContext);
}
