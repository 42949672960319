import type { ReadConfig } from 'api-read-hook';

import { useApiRead } from 'api-read-hook';

export type V = {
  '@id': string;
  '@type': 'MyStatus';
  user: {
    '@id': string;
    '@type': 'User';
    id: string;
    metaData: {
      '@id': string;
      '@type': 'UserMetaData';
      currentLocation: {
        '@id': string;
        '@type': 'Location';
        lat: number;
        long: number;
        place: string;
      };
      isVeriffied: boolean;
      isAnonymous: boolean;
      gender: string | null;
      ageRange: string | null;
      aboutYou: string | null;
      postcode: string | null;
      singleParentJourney: string | null;
    } | null;
    email: string | null;
    loginType: string | null;
    createdAt: number | null;
    lastActiveDate: string | null;
    isDatingShow: boolean;
    mode: string | null;
    communityUsername: string | null;
    communityProfileImage: string | null;
    firstName: string | null;
    lastName: string | null;
    datingProfileImage: string | null;
    hasCommunitySubscription: boolean;
    hasDatingSubscription: boolean;
    feedSettings: {
      '@id': string;
      '@type': 'UserFeedSettings';
      id: number;
      visibility: string;
      distance: number | null;
    } | null;
    datingProfile: {
      '@id': string;
      '@type': 'DatingProfile';
      about: string | null;
      height: number | null;
      jobTitle: string | null;
      workInfo: {
        '@id': string;
        '@type': 'UserMetaLegend';
        id: number;
        name: string;
      } | null;
      birthDate: string | null;
      imagePaths: Array<string> | null;
      heightInFeet: string;
      age: number | null;
    } | null;
    onboardingAnswers: Array<{
      '@id': string;
      '@type': 'UserOnboardingAnswer';
      question: {
        '@id': string;
        '@type': 'OnboardingQuestion';
        id: number;
        name: string;
        sequence: number;
      };
      questionAnswer: {
        '@id': string;
        '@type': 'OnboardingQuestion';
        id: number;
        name: string;
        sequence: number;
      } | null;
      answerText: string | null;
    }>;
    parentingInfoMappings: Array<{
      '@id': string;
      '@type': 'UserParentingInfoMapping';
      parentingInfo: {
        '@id': string;
        '@type': 'UserMetaLegend';
        id: number;
        name: string;
      };
    }>;
    childInfos: Array<{
      '@id': string;
      '@type': 'ChildInfo';
      gender: string;
      dob: string;
    }>;
    isModerator: boolean;
  };
  veriffStatus: string | null;
  communityCombinedBlockList: Array<string>;
  datingCombinedBlockList: Array<string>;
  hasUnreadNotifications: boolean;
  lastDatingLikeReceivedAt: number | null;
  postsCreatedCount: number;
  communitySuspendedUserIds: Array<string>;
  datingSuspendedUserIds: Array<string>;
  isSuspendedCommunity: boolean;
  isSuspendedDating: boolean;
  combinedBlockList: Array<string>;
};

export function useReadItem(options?: ReadConfig) {
  return useApiRead<V>(`/my-status`, options);
}
