export default function makeQueryString(
  params: {
    [key: string]: string | string[] | number | boolean | undefined;
  } = {}
): string {
  const printValue = (v: string | number | boolean): string => {
    if (typeof v === 'boolean') return v ? 'true' : 'false';
    return v.toString();
  };

  const printPart = ([k, v]: [string, any]): string => {
    return Array.isArray(v)
      ? v.map((x) => `${k}=${printValue(x)}`).join('&')
      : `${k}=${printValue(v)}`;
  };

  const qs = Object.entries(params)
    .filter(([, v]) => v != null)
    .map(printPart)
    .join('&');
  return qs ? `?${qs}` : '';
}
