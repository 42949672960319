import { Container } from '@mui/material';

type Props = {
  message?: string;
};

export default function ScreenLoading({ message }: Props) {
  return (
    <Container maxWidth={false}>
      <div>{message ?? 'Loading...'}</div>
    </Container>
  );
}
