const AwsEnvironments = {
  production: {
    APP_ENV: 'production',
    ADJUST_APP_TOKEN: 'r7225rgk4xs0',
    API_HOST_DATING: 'dating-api-prod.frolo.co.uk',
    API_URL: 'https://apis.frolo.co.uk/v7',
    APP_STORE_ID: '1469900100',
    AWS_OAUTH_DOMAIN: 'frolo-prod.auth.eu-west-2.amazoncognito.com',
    AWS_OAUTH_REDIRECT_SIGN_IN:
      'https://dating-api-prod.frolo.co.uk/socialLogin',
    AWS_OAUTH_REDIRECT_SIGN_OUT:
      'https://dating-api-prod.frolo.co.uk/socialLogin',
    AWS_USER_POOLS_ID: 'eu-west-2_uGVrUl4kr',
    AWS_USER_POOLS_WEB_CLIENT_ID: '4dcljomjk2of0bf5d9r3971sb8',
    AWS_REGION: 'eu-west-2',
    GOOGLE_CLIENT_ID:
      '938827313626-3n2d4ph36b5fsrpti33t7cr5o3sltlec.apps.googleusercontent.com',
    IMAGES_BASE_URL: 'https://dxklf4ip4tsda.cloudfront.net',
    SENTRY_DSN:
      'https://ddd8cca8511244d58e0dc83420fffdb3@o1270996.ingest.sentry.io/6462353',
    SENTRY_ENVIRONMENT: 'production',
    SERVER_ROOT: 'https://api.frolo.co.uk',
  },
  staging: {
    APP_ENV: 'staging',
    ADJUST_APP_TOKEN: 'r7225rgk4xs0',
    API_HOST_DATING: 'dating-api-stage.frolo.co.uk',
    API_URL: 'https://services-uat.frolo.co.uk',
    APP_STORE_ID: '1636445633',
    AWS_OAUTH_DOMAIN: 'frolo-stage-2.auth.eu-west-2.amazoncognito.com',
    AWS_OAUTH_REDIRECT_SIGN_IN:
      'https://dating-api-stage.frolo.co.uk/socialLogin',
    AWS_OAUTH_REDIRECT_SIGN_OUT:
      'https://dating-api-stage.frolo.co.uk/socialLogin',
    AWS_USER_POOLS_ID: 'eu-west-2_Em4cMkK3B',
    AWS_USER_POOLS_WEB_CLIENT_ID: '7n0f7o35ebk840mscjd2u80g4e',
    AWS_REGION: 'eu-west-2',
    GOOGLE_CLIENT_ID:
      '216702177403-88bn12ioe9em688e6ip1o4op8tevth43.apps.googleusercontent.com',
    IMAGES_BASE_URL: 'https://d29fhenvf65age.cloudfront.net',
    SENTRY_DSN:
      'https://ddd8cca8511244d58e0dc83420fffdb3@o1270996.ingest.sentry.io/6462353',
    SENTRY_ENVIRONMENT: 'development',
    SERVER_ROOT: 'https://frolo-staging.herokuapp.com',
  },
  dev: {
    APP_ENV: 'dev',
    ADJUST_APP_TOKEN: 'r7225rgk4xs0',
    API_HOST_DATING: 'dating-api-stage.frolo.co.uk',
    API_URL: 'https://services-uat.frolo.co.uk',
    APP_STORE_ID: '1636445633',
    AWS_OAUTH_DOMAIN: 'frolo-stage-2.auth.eu-west-2.amazoncognito.com',
    AWS_OAUTH_REDIRECT_SIGN_IN:
      'https://dating-api-stage.frolo.co.uk/socialLogin',
    AWS_OAUTH_REDIRECT_SIGN_OUT:
      'https://dating-api-stage.frolo.co.uk/socialLogin',
    AWS_USER_POOLS_ID: 'eu-west-2_d8718CHH7',
    AWS_USER_POOLS_WEB_CLIENT_ID: '5c1rf1t9hgqbad5denbt1t10ga',
    AWS_REGION: 'eu-west-2',
    GOOGLE_CLIENT_ID:
      '216702177403-88bn12ioe9em688e6ip1o4op8tevth43.apps.googleusercontent.com',
    IMAGES_BASE_URL: 'https://d29fhenvf65age.cloudfront.net',
    SENTRY_DSN:
      'https://ddd8cca8511244d58e0dc83420fffdb3@o1270996.ingest.sentry.io/6462353',
    SENTRY_ENVIRONMENT: 'development',
    SERVER_ROOT: 'http://localhost:8000',
  },
};

export default AwsEnvironments;
