import { Container } from '@mui/material';

type Props = {
  error?: Error;
  origin?: string;
};

export default function ScreenError({ error, origin }: Props) {
  return (
    <Container maxWidth={false}>
      <div>{error?.message}</div>
      {!!origin && <div>Origin: {origin}</div>}
    </Container>
  );
}
