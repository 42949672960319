import AwsEnvironments from './AwsEnvironments';

const envs = {
  production: AwsEnvironments.production,
  staging: AwsEnvironments.staging,
  dev: AwsEnvironments.staging,
};

const env: keyof typeof envs =
  (process.env.NEXT_PUBLIC_APP_ENV as any) ?? 'staging';

const envConfig = AwsEnvironments[env as keyof typeof AwsEnvironments];

const config = {
  ...envConfig,
  awsConfig: {
    aws_project_region: 'eu-west-2',
    aws_cognito_region: 'eu-west-2',
    aws_user_pools_id: envConfig.AWS_USER_POOLS_ID,
    aws_user_pools_web_client_id: envConfig.AWS_USER_POOLS_WEB_CLIENT_ID,
    oauth: {
      domain: envConfig.AWS_OAUTH_DOMAIN,
      responseType: 'code',
      scope: [
        'phone',
        'email',
        'profile',
        'openid',
        'aws.cognito.signin.user.admin',
      ],
      redirectSignIn: envConfig.AWS_OAUTH_REDIRECT_SIGN_IN,
      redirectSignOut: envConfig.AWS_OAUTH_REDIRECT_SIGN_OUT,
    },
  },
};

export default config;
