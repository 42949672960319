'use client';

import { ScreenLoading } from '@/components';
import { Auth } from 'aws-amplify';
import { Box } from '@mui/material';
import { useRouter } from 'next/navigation';
import { jwtDecode } from 'jwt-decode';
import * as React from 'react';
import { Api, useApiCallable } from '@/api';

type AmplifyUser = {
  sub: string;
  zoneinfo: string;
  birthdate: string;
  'custom:profile_image': string;
  gender: string;
  iss: string;
  'custom:children_details': string;
  preferred_username: string;
  'custom:fk_parenting_info': string;
  'custom:longitude': string;
  'custom:postcode': string;
  'custom:single_journey': Date;
  'custom:no_of_children': string;
  updated_at: number;
  auth_time: number;
  'custom:age': string;
  'custom:age_range': string;
  exp: number;
  'custom:height': string;
  iat: number;
  jti: string;
  email: string;
  email_verified: boolean;
  address: {
    formatted: string;
  };
  phone_number_verified: boolean;
  'cognito:username': string;
  given_name: string;
  picture: string;
  origin_jti: string;
  aud: string;
  event_id: string;
  token_use: string;
  'custom:latitude': string;
  name: string;
  family_name: string;
  'custom:social_login_id': string;
  is_moderator: boolean;
};

const AmplifyAuthContext = React.createContext<{
  amplifyUser: null | AmplifyUser;
  recheckAuth: (withEvents: boolean) => Promise<void>;
  signIn: (
    email: string,
    password: string,
    options?: { preserveSignUpData?: boolean }
  ) => Promise<void>;
  signOut: () => Promise<void>;
}>({
  amplifyUser: null,
  recheckAuth: (() => {}) as any,
  signIn: (() => {}) as any,
  signOut: (() => {}) as any,
});

type Props = {
  children: React.ReactNode;
};

export function AmplifyAuthContextProvider({ children }: Props) {
  const callApi = useApiCallable();

  const [user, setUser] = React.useState<AmplifyUser | null | undefined>();
  const router = useRouter();

  const signOut = React.useCallback(async () => {
    setUser(null);
    await Auth.signOut();
    router.push('/');
  }, [router]);

  const checkCurrentValue = React.useCallback(async () => {
    let idToken = null;
    try {
      const session = await Auth.currentSession();

      idToken = session.getIdToken().getJwtToken();
      // const accessToken = session.getAccessToken().getJwtToken();
    } catch (err) {
      setUser(null);
    }

    if (idToken) {
      const idTokenPayload = jwtDecode(idToken);
      // const accessTokenPayload = jwtDecode(accessToken);

      const jwtUserData = idTokenPayload as AmplifyUser;
      setUser(jwtUserData);
    }
  }, []);

  // useInterval(() => checkCurrentValue(true), 60000);

  const signIn = async (email: string, password: string): Promise<void> => {
    await Auth.signIn(email.toLowerCase().trim(), password);
    const response = await callApi<Api.MyStatus.V, unknown>('/my-status');

    if (response) {
      const userData = response.data.user;
      if (!userData.isModerator) {
        console.error('User is not a moderator');
        setUser(null);
        await Auth.signOut();
        throw new Error('You are not authorised to access this site');
      }
    }
    await checkCurrentValue();
  };

  React.useEffect(() => {
    checkCurrentValue();
  }, [checkCurrentValue]);

  if (user === undefined) {
    return (
      <Box sx={{ height: '100vh' }}>
        <ScreenLoading message="Authenticating" />
      </Box>
    );
  }

  return (
    <AmplifyAuthContext.Provider
      value={{
        /**
         * undefined = has not checked yet (loading state)
         * null = not authenticated
         */
        amplifyUser: user,
        recheckAuth: checkCurrentValue,
        signIn,
        signOut,
      }}
    >
      {children}
    </AmplifyAuthContext.Provider>
  );
}

export function useAmplifyAuthContext() {
  return React.useContext(AmplifyAuthContext);
}
