import config from '@/config';

export type ApiRequestOptions<W> = {
  jsonBody?: W;
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
  jwt?: string | null;
};

export type ApiResponse<T> = {
  status: number;
  headers: object;
  data: T;
};

export class ApiError extends Error {
  requestOptions: ApiRequestOptions<any>;
  response: ApiResponse<any>;
  constructor(
    url: string,
    requestOptions: ApiRequestOptions<any>,
    response: ApiResponse<any>
  ) {
    super(`${response.status} on ${requestOptions.method ?? 'GET'} ${url}`);
    this.requestOptions = requestOptions;
    this.response = response;
  }
}

export default async function froloApiRequest<R, W>(
  url: string,
  options: ApiRequestOptions<W>
): Promise<ApiResponse<R>> {
  const { method = 'GET', jwt, jsonBody } = options;

  let body: undefined | string | FormData;
  if (jsonBody) body = JSON.stringify(jsonBody);

  const headers = {
    Accept: 'application/ld+json',
    'Cache-Control': 'no-cache',
    ...(jsonBody ? { 'content-type': 'application/json' } : {}),
    ...(jwt ? { Authorization: `Bearer ${jwt}` } : {}),
  };

  const fetchResponse = await fetch(`${config.SERVER_ROOT}${url}`, {
    method,
    headers,
    body,
  });

  const text = fetchResponse.status === 204 ? '' : await fetchResponse.text();

  // Debugging info
  if (!fetchResponse.ok && process.env.NODE_ENV === 'development') {
    console.log(`>> ${method} ${url}`);
    Object.entries(headers).forEach((h) => console.log(`>> ${h[0]}: ${h[1]}`));
    body && console.log(body);
    console.log('');
    console.log(`<< ${fetchResponse.status}`);
    fetchResponse.headers.forEach((v: string, k: string) => {
      console.log(`<< ${k}: ${v}`);
    });
    console.log(text);
    console.log('');
  }

  const contentTypes = fetchResponse.headers.get('content-type') || '';
  if (
    !(
      contentTypes.includes('application/json') ||
      contentTypes.includes('application/ld+json')
    ) &&
    fetchResponse.status !== 204
  ) {
    throw new Error(
      `${method} ${url} failed (${fetchResponse.status}); no json: ${text}`
    );
  }

  let data: R;
  try {
    data = text ? JSON.parse(text) : null;
  } catch (err) {
    throw new Error(`apiFetchRaw: Could not parse JSON: "${text}"`);
  }

  const apiResponse: ApiResponse<R> = {
    data,
    status: fetchResponse.status,
    headers: fetchResponse.headers,
  };

  if (!fetchResponse.ok) {
    throw new ApiError(url, options, apiResponse);
  }
  return apiResponse;
}
