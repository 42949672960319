'use client';

import { Box, Container, Stack, Typography } from '@mui/material';

type Props = {
  title?: string;
  children: React.ReactNode;
  limitMaxWidth?: boolean;
};

export default function BaseScreen({
  children,
  title,
  limitMaxWidth = true,
}: Props) {
  return (
    <>
      <Box
        component="main"
        sx={{ flexGrow: 1, py: 8, ...(!limitMaxWidth && { pb: 0, pt: 4 }) }}
      >
        <Container maxWidth={limitMaxWidth && 'xl'}>
          <Stack spacing={4}>
            {title && <Typography variant="h4">{title}</Typography>}
            {children}
          </Stack>
        </Container>
      </Box>
    </>
  );
}
