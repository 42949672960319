import React from 'react';
import {
  IconButton,
  InputAdornment,
  OutlinedInput,
  SvgIcon,
} from '@mui/material';
import SearchMdIcon from '@untitled-ui/icons-react/build/esm/SearchMd';
import ClearIcon from '@untitled-ui/icons-react/build/esm/XClose';

type Props = {
  value: string;
  onEnterPress: (value: string) => void;
  onChange: (value: string) => void;
  onClearInput: () => void;
  placeholder?: string;
};

export default function SearchInputField({
  value,
  onEnterPress,
  onChange,
  onClearInput,
  placeholder,
}: Props) {
  return (
    <OutlinedInput
      sx={{
        display: 'flex',
        flex: 1,
        width: '500px',
      }}
      value={value}
      onChange={(ev) => onChange(ev.target.value)}
      onKeyDown={(ev: any) => {
        if (ev.key === 'Enter') {
          onEnterPress(ev.target.value);
          ev.preventDefault();
        }
      }}
      placeholder={placeholder ?? 'Search'}
      startAdornment={
        <InputAdornment position="start">
          <SvgIcon>
            <SearchMdIcon />
          </SvgIcon>
        </InputAdornment>
      }
      endAdornment={
        value.trim().length > 0 && (
          <InputAdornment position="end">
            <IconButton onClick={onClearInput}>
              <ClearIcon />
            </IconButton>
          </InputAdornment>
        )
      }
    />
  );
}
