import * as React from 'react';
import { Auth } from 'aws-amplify';

import {
  alertNetworkError,
  alertUnhandledError,
  isNetworkError,
  logError,
} from '../util';
import froloApiRequest, {
  ApiError,
  ApiRequestOptions,
  ApiResponse,
} from './frolo-api-request';

/**
 * Wrap `froloApiRequest` with app-specific customisations:
 *
 * - If user is logged in, add their token to the request
 * - Default error handling logic
 *
 * The returned function (callApi) returns the API response on success, or
 * undefined on failure.  Pass in an `errorHandler` option to override default
 * error handling behaviour.
 */
export default function useApiCallable() {
  return React.useCallback(async function callApi<R, W>(
    url: string,
    requestOptions?: Omit<ApiRequestOptions<W>, 'token' | 'fcmToken'>,
    moreOptions?: {
      errorHandler?: (
        err: ApiError | Error,
        originalHandler: (err: ApiError | Error) => void
      ) => void;
      addSubmitError?: (field: any, error: string) => void;
    }
  ): Promise<ApiResponse<R> | undefined> {
    function handleError(err: ApiError | Error) {
      const errData = (err as any).response?.data || {};

      if (
        err instanceof ApiError &&
        err.response?.status === 400 &&
        errData.status === false &&
        errData.message
      ) {
        // Seems to be an expected error form dating API, so show to user
        alert(`Error: ${errData.message}`);
      } else if (isNetworkError(err)) {
        alertNetworkError();
      } else {
        const eventId = logError(err, 'callApi.handleError');
        alertUnhandledError(eventId);
      }
    }

    try {
      const jwt = (await Auth.currentSession()).getIdToken().getJwtToken();

      const response = await froloApiRequest<R, W>(url, {
        jwt,
        ...requestOptions,
      });
      return response;
    } catch (err: any) {
      if (err instanceof ApiError && err.response?.status === 401) {
        window.location.reload();
      } else if (moreOptions?.errorHandler) {
        moreOptions.errorHandler(err, handleError);
      } else {
        handleError(err);
      }
      return undefined;
    }
  }, []);
}
